// const cellStyles = {
//   billingDate: {backgroundColor: '#ab85ad', color: '#fff'},
//   amountReceivableWithoutTax: {backgroundColor: '#eebcbc', color: '#545454'},
//   amountReceivableWithTax: {backgroundColor: '#ef9a9a', color: '#fff'}
// }

import { simplePaymentStatusMapping } from "../misc"
import { currencyFormatter, externalDspGetter, fromDateGetter, holdingNameGetter, multiMonthGetter, taxGetter, toDateGetter } from "../strings"

const getFilesTestData = (params) => {
  const {data} = params
  const {purchase_order, id} = data
  if(purchase_order){
    const parseData = JSON.parse(purchase_order)
    return {
      campaign_id: id,
      files: JSON.stringify(parseData.map((fileData, index) => ({
        id: index,
        file: fileData.file,
        created_at: fileData.created_at,
        billed_at: null,
        billed: false,
        payed_at: null,
        payed: false,
        null: false
      })))
    }
  }
  return {
    campaign_id: id,
    files: JSON.stringify([])
  }
}

export const columnDefs = ({openPoModal})=> ([
    {
      headerName: 'Campaña',
      headerClass: 'column-group-1',
      children: [
        {
          headerName: 'ID',
          field: 'campaign_id',
          filter: false,
          // sort: 'desc',
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true,
          pinned: 'left',
          lockPinned: true,
          width: 150
        },
        {
          headerName: 'Nombre campaña',
          field: 'campaign_name',
          filter: false,
          width: 300,
          wrapText: true
        },
        {
            headerName: 'Multimes',
            valueGetter: multiMonthGetter,
            filter: false,
            width: 200,
            wrapText: true
        },
        {
          headerName: 'Fecha de Inicio',
          valueGetter: fromDateGetter,
          filter: false,
          width: 180
        },
        {
          headerName: 'Fecha de Fin',
          valueGetter: toDateGetter,
          filter: false,
          width: 150
        },
      ]
    },
    {
      headerName: 'Pantalla',
      headerClass: 'column-group-4',
      children: [
        {
          headerName: 'ID Empresa',
          field: 'company_id',
          filter: false,
          width: 150
        },
        {
          headerName: 'Empresa Pantallero',
          field: 'company_name',
          filter: false,
          width: 200
        },
        {
          headerName: 'Holding',
          valueGetter: holdingNameGetter,
          filter: false,
          width: 200
        },
        {
          headerName: 'País',
          field: 'display_country',
          filter: false,
          width: 150
        }
      ]
    },
    {
      headerName: 'Operación',
      headerClass: 'column-group-2',
      children: [
        {
          headerName: 'Divisa',
          field: 'currency_to_pay',
          width: 100,
          filter: false
        },
        {
            headerName: 'Total consumido',
            field: 'total_price_consumed',
            valueFormatter: currencyFormatter,
            filter: false,
            width: 350,
            colId: 'total_price_consumed',
        },
        {
            headerName: 'Total presupuestado',
            field: 'total_price_with_profit',
            valueFormatter: currencyFormatter,
            filter: false,
            width: 350
        },
        //Agregar "Estado de facturación"
        {
          headerName: 'Pagado',
          valueGetter: getFilesTestData,
          cellRenderer: 'billingAmountRenderer',
          cellRendererParams: {
            variant: 'payed'
          },
          filter: false,
          width: 120,
          cellStyle: {paddingLeft: '5px', paddingRight: '5px'},
          pinned: 'left',
          refData: simplePaymentStatusMapping()
        },
        {
            headerName: 'Tax',
            valueGetter: taxGetter,
            width: 150
        },
        {
            headerName: 'Tipo de venta',
            valueGetter: externalDspGetter,
            filter: false,
            width: 200,
            wrapText: true
        },
        {
            headerName: 'Documentación',
            valueGetter: getFilesTestData,
            filter: false,
            cellRenderer: (params) => {
              if (params.value) {
                const button = document.createElement('a')
                button.innerText = 'Ver'
                button.addEventListener('click', (event) => {
                  event.stopPropagation()
                  openPoModal(params.value)
                })
                return button
              }else{
                return '-'
              }
            }
        }
      ]
    },
  ])