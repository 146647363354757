<template>
    <div class="p-12 w-full lg:h-screen">
        <goals
            :token="queryParam.token"
        ></goals>
    </div>
</template>
<script>
    import Goals from './Goals.vue';

    export default {
        components: {
            Goals
        },
        computed: {
            queryParam() {
                return {
                    token: this.$route.query.token
                }
            }
        }
    }
</script>